<template>
  <div style="height: 100vh">
    <div class="columns is-centered is-vcentered">
      <div class="column is-half" style="height: 100%">
        <div class="card">
          <div class="card-header has-background-dark">
            <img width="300" :src="`/logos/${site}/bank2.svg`"  style="margin: 0 auto"/>
          </div>
          <div class="card-content is-centered">
            <p class="mb-4 has-text-centered">Lütfen aşağıda belirtilen adrese {{
              transaction.total }} {{
    transaction.toCurrency }} tutarında havale & eft yaptığınızdan emin olun .</p>
            <hr/>
            <b-field label="Banka" v-if="transaction.bankAccount">
              <b-input v-model="transaction.bankAccount.bankList.name" disabled expanded id="bank" />
              <p class="control">
                <b-button type="is-success" icon-left="clipboard-check-multiple" @click="copy('bank')"></b-button>
              </p>
            </b-field>
            <b-field label="Alıcı Ad & Soyad" v-if="transaction.bankAccount">
              <b-input v-model="transaction.bankAccount.name" disabled expanded id="bankAccountName" />
              <p class="control">
                <b-button type="is-success" icon-left="clipboard-check-multiple"
                  @click="copy('bankAccountName')"></b-button>
              </p>
            </b-field>
            <b-field label="Alıcı Adresi" v-if="transaction.bankAccount">
              <b-input v-model="transaction.bankAccount.iban" disabled expanded id="bankAccount" />
              <p class="control">
                <b-button type="is-success" icon-left="clipboard-check-multiple" @click="copy('bankAccount')"></b-button>
              </p>
            </b-field>
            <b-field label="Tutar(₺)" v-if="transaction.total">
              <b-input v-model="transaction.total" disabled expanded />
            </b-field>
            <hr />
            <p class="mb-3 notification has-background-success-light p-3">
              İşlem kontrol edildikten sonra bakiyenize yansıyacaktır. Lütfen sayfadan ayrılmayınız
            </p>
            <hr />
            <div class="is-flex is-full is-justify-content-end">
              <b-button type="is-danger" @click="cancel">Vazgeç</b-button>
              <b-button type="is-primary" expanded class="ml-3" @click="pay">Ödemeyi Gerçekleştirdim</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TransactionService } from '@/services'
export default {
  name: 'BankTransferPay',
  data() {
    return {
      transaction: {},
      loading: false,
      interval: null
    }
  },
  mounted() {
    const html = document.getElementsByTagName('html')[0]
    html.classList = []
    this.init()
    /*this.interval = setInterval(() => {
      this.init()
    },10000)*/
  },
  methods: {
    pay() {
      this.loading = true
      TransactionService.bankTransferPay({ transactionId: this.transaction.uuid })
        .then(res => {
          this.$buefy.toast.open({ message: res.data.message, position: 'is-top', type: 'is-warning' })
          setTimeout(() => {
            if (res.data.redirectUrl) {
              window.location.href = res.data.redirectUrl
            } else {
              this.$router.push({ name: 'paymentError' })
            }
          }, 3000)
        })
        .finally(() => this.loading = false)
    },
    init() {
      TransactionService.check(this.$route.params.id)
        .then(({ data }) => {
          if (data.bankAccount.iban === 'XPAYSISTEM' || data.bankAccount.iban === 'ASPAY') {
            const accountParse = data.trxId.split(".")
            data.bankAccount.iban = accountParse[0]
            data.bankAccount.name = accountParse[2]
            data.bankAccount.bankList.name = accountParse[1]
            // data.bankList.logo = data.bankAccount.iban = accountParse[3]
          }
          this.transaction = data
          if (data.status === 'SUCCESSFUL') {
            // clearInterval(this.interval)
            if (data.successRedirectUrl)
              window.location.href = data.successRedirectUrl
            else
              this.$router.push({ name: 'paymentSuccess' })
          }
          if (data.status === 'UNSUCCESSFUL') {
            // clearInterval(this.interval)
            if (data.failRedirectUrl)
              window.location.href = data.failRedirectUrl
            else
              this.$router.push({ name: 'paymentError' })
          }
        })
        .catch(err => {
          this.$buefy.toast.open({ message: err.message, position: 'is-top-right', type: 'is-danger' })
        })
    },
    copy(val) {
      const copyText = document.getElementById(val)
      copyText.select()
      copyText.setSelectionRange(0, 99999) /* For mobile devices */
      navigator.clipboard.writeText(copyText.value)
      this.$buefy.toast.open({
        duration: 3000,
        message: 'Kopyalandı!',
        position: 'is-bottom',
        type: 'is-success'
      })
    },
    cancel() {
      this.loading = true
      TransactionService.cancel({ transactionId: this.$route.params.id })
        .then(res => {
          this.$buefy.toast.open({
            message: res.data.message,
            position: 'is-top-right',
            type: 'is-success',
            duration: 2000
          })
          setTimeout(() => {
            window.location.href = res.data.redirectUrl
          }, 2000)
        })
        .catch(err => this.$buefy.toast.open({ message: err.message, position: 'is-bottom', type: 'is-info' }))
        .finally(() => this.loading = false)
    }
  },
  beforeDestroy() {
    // clearInterval(this.interval)
  }
}
</script>

<style>
.qrcode>canvas {
  border: 6px solid #ddd;
  padding: .5rem;
  border-radius: 12px;
}

.input[type="text"]::placeholder {
  color: #000000 !important
}

.input[type="text"] {
  color: #000000 !important
}</style>
